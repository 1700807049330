<template>
  <div class="container-paises" :class="{ girar: paises }">
    <!-- Selected -->
    <div @click="paises = !paises" :class="'item-pais pais-selecionado'">
      <img v-if="showSelectedImg" :src="require(`@/assets/locales/flag-${pais_code}.png`)" />
      {{ paises ? $t("locales.close") : $t("locales.trocar") }}
    </div>
    <ul class="paises" data-anima="top" v-show="paises">
      <!-- Search -->
      <div class="container-pesquisa mt-3 mt-md-0">
        <div class="inputSearch">
          <img src="@/assets/icons/search.svg" class="Pointer search" />
          <input type="text" :placeholder="$t('locales.placeholder')" v-model="search" class="input-busca" />
        </div>
      </div>
      <!-- Options -->
      <div v-for="list in filteredItems" :key="list.sigla">
        <li :class="'item-pais'" @click="changeCountry(list.sigla, list.pais)">
          <img :src="require(`@/assets/locales/flag-${list.sigla}.png`)" />
          {{ list.sub }} | {{ list.pais }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
// Services

import MetaService from "@/services/resources/MetaService";
const service = MetaService.build();

import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();

import { EventBus } from "@/main.js";
import Cookies from "js-cookie";

export default {
  name: "Locales",
  props: ["off"],
  data() {
    return {

      paises: false,
      pais_nome: "BR | Português",
      pais_code: "BR",
      search: "",
      showSelectedImg: true,
      lists: [],
    };
  },
  computed: {
    pais() {
      return this.$store.state.metas.metas.pais || "BR"
    },
    filteredItems() {
      return !this.search.length
        ? this.lists
        : this.lists.filter(
          (item) =>
            item.pais
              .toLowerCase()
              .includes(this.search.toLowerCase().trim()) ||
            item.sub
              .toLowerCase()
              .includes(this.search.toLowerCase().trim()) ||
            item.sigla
              .toLowerCase()
              .includes(this.search.toLowerCase().trim())
        );
    },
  },
  mounted() {
    this.getMetas();
    this.fetchCountry();
    this.$root.$on("getCountry", () => {
      this.fetchCountry();
    });
  },
  methods: {
    async getMetas() {
      await this.$store.dispatch("fetchMetas", [
        "pais",
      ]);
    },
    fetchCountry() {
      this.lists = [
        { sigla: "BR", sub: "Brazil", pais: "Brasil" },
        { sigla: "US", sub: "United States", pais: "United States" },
        { sigla: "ES", sub: "España", pais: "Spain" },
        { sigla: "CO", sub: "Colombia", pais: "Colombia" },
        { sigla: "MX", sub: "México", pais: "Mexico" },
      ];
      if (this.$route.path === "/minha-conta") {
        serviceMemberMeta.search("keys[]=pais").then((resp) => {     
          if (resp.pais !== undefined) {
            if (resp.pais === null) {
              this.selectCountry("BR");
            } else {
              var myArray = this.lists;
              var result = myArray.filter((x) => x.sigla === resp.pais);
              if (JSON.stringify(result) === "[]") {
                this.setCountry("BR", "Brasil");
              } else {
                this.setCountry(result[0].sigla, result[0].pais);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
    setCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${code} | ${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = false;
    },
    changeCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${code} | ${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = false;
      serviceMemberMeta.postID({ id: "pais", value: code }).then(() => {
        this.$store.dispatch(`updateMetas`, { id: "pais", value: code });
        this.$bvToast.toast(this.$t("locales.msg"), {
          title: this.$t("locales.title"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
      });
      this.showSelectedImg = true;
    },
    selectCountry(locale) {
      let permission = Cookies.get("access_level");
      if (permission !== "admin" && permission !== "owner") {
        this.search = "";
        if (locale === "BR") {
          this.$i18n.locale = "pt";
        } else if (
          locale == "AR" ||
          locale == "CO" ||
          locale == "CL" ||
          locale == "EC" ||
          locale == "GT" ||
          locale == "MX" ||
          locale == "PE" ||
          locale == "UY" ||
          locale == "ES"
        ) {
          this.$i18n.locale = "es";
        } else {
          this.$i18n.locale = "en";
        }
      }
    },
  },
  created() {
    EventBus.$on("changeLocale", (data) => {
      localStorage.setItem("location", data.locale);
      if (data.locale == "BR" || data.locale == "pt") {
        this.selectCountry("BR");
        this.pais_nome = "BR | Português";
        this.pais_code = "BR";
      } else if (
        data.locale == "AR" ||
        data.locale == "CO" ||
        data.locale == "CL" ||
        data.locale == "EC" ||
        data.locale == "GT" ||
        data.locale == "MX" ||
        data.locale == "PE" ||
        data.locale == "UY" ||
        data.locale == "ES"
      ) {
        this.selectCountry(data.locale);
        let pais = this.lists.filter((item) => item.sigla === data.locale);
        this.pais_nome = `${data.locale} | ${pais}`;
        this.pais_code = data.locale;
      } else {
        this.selectCountry("US");
        this.pais_nome = "US | English";
        this.pais_code = "US";
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.container-paises-off {
  position: absolute !important;
  right: 0;
  top: -13px !important;
  color: var(--fontcolor);

  .item-pais img {
    width: 25px;
    height: 25px;
  }

  .paises {
    position: absolute !important;
    margin-left: -20px;
    color: var(--fontcolor);
  }
}

/* // paises */
.container-paises {
  position: absolute;
  z-index: 9999;
}

.input-busca {
  width: 100% !important;
  height: 40px !important;
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
  transition: 0.5s;
  margin-bottom: 15px;
}

.input-busca::placeholder {
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 32px;
  top: 27px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.paises {
  background: var(--backgroundcolor);
  width: max-content;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 5px;
}

.pais-selecionado {
  display: flex;
  justify-content: space-between;
}

.pais-selecionado::after {
  content: "";
  background: url("../../assets/icons/arrow-fill.svg") no-repeat center center;
  width: 22px;
  height: 22px;
  margin-right: 12px;
}

.girar .pais-selecionado::after {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.item-pais {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 15px;
  cursor: pointer;
  padding: 7px 10px;
  padding-right: 0;
  transition: 0.3s;
  color: var(--fontcolor);
  position: relative;
  margin-bottom: 5px;
  font-weight: 500;
}

.item-pais::before {
  content: "";
  width: 5px;
  height: 5px;
  background: transparent;
  position: absolute;
  left: -5px;
  border-radius: 50%;
}

.item-pais+.item-pais {
  margin-top: 10px;
}

.item-pais:hover {
  color: var(--basecolor);
}

.item-pais:hover::before {
  background: var(--basecolor);
}

.item-pais img {
  width: 35px;
  height: 35px;
}

/* mobile */
@media screen and (max-width: 769px) {

  .container-paises,
  .paises,
  .item-pais {
    width: auto;
  }

  .paises {
    margin-right: -7px;
    padding: 10px;
  }

  .pais-selecionado::after,
  .item-pais::before {
    display: none;
  }

  .item-pais {
    font-size: 11px;
    gap: 5px;
  }

  .item-pais img {
    width: 15px;
    height: 15px;
  }
}

.custom-border {
  border: 0.5px solid #ededf0;
  border-radius: 5px;
}

.custom-border:hover {
  border-color: #00e4a0 !important;
}

.custom-image img {
  width: 25px;
  height: 25px;
}

.custom-selected {
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-width {
  width: 100%;
}
</style>
